import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import AllIdeetipp from "../components/AllIdeetipp"
import SEO from "../components/SEO"

export default function Home() {
  return (
    <Layout>
      <SEO
        title="Home "
        description="Ideetipp ist die Online-Adresse für alle deine Bedürfnisse. Von Marken, Produkten bis hin zu Tipps und Tricks – wir halten dich auf dem Laufenden!"
      />
      <main className="page">
        <header className="hero">
          <StaticImage
            src="../assets/images/main.jpg"
            alt="ideetipp"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
          <div className="hero-container">
            <div className="hero-text">
              <h1>ideetipp.de</h1>
              <h4>Ideen und Tipps für alle Lebenslagen</h4>
            </div>
          </div>
        </header>
        
        <AllIdeetipp />
      </main>
    </Layout>
  )
}
