import React from "react"
import setupTags from "../utils/setupTags"
import { Link } from "gatsby"
import slugify from "slugify"
import deChar from "../utils/deChar"

const TagsList = ({ ideetipp }) => {
  const newTags = setupTags(ideetipp)

  return (
    <div className="tag-container">
      <h4>Kategorien</h4>
      <div className="tags-list">
        {newTags.map((tag, index) => {
          const [text, value] = tag
          const deDE = deChar(text)
          const slug = slugify(deDE, { lower: true })
          return (
            <Link to={`/tags/${slug}`} key={index}>
              {text} ({value})
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default TagsList
