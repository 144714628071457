import React from "react"
import TagsList from "./TagsList"
import IdeetippList from "./IdeetippList"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allContentfulIdeetipp(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        createdAt
        subtitle
        durationTime
        prepTime
        description {
          description
        }
        content {
          tags
        }
        image {
          id
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  }
`

const AllIdeetipp = () => {
  const data = useStaticQuery(query)
  const ideetipp = data.allContentfulIdeetipp.nodes
  return (
    <section className="recipes-container">
      <TagsList ideetipp={ideetipp} />
      <IdeetippList ideetipp={ideetipp} />

      {/*<p>Ideetipp ist die Online-Adresse für alle Ihre Bedürfnisse. 
        Von Marken, Produkten bis hin zu Tipps und Tricks – wir sind Ihr One-Stop-Shop 
        für alle Ideen und Antworten auf Ihre alltäglichen Probleme. 
        Bleiben Sie mit den neuesten Trends auf dem Laufenden. 
        Wir halten Sie über die neuesten Marken, Produkte, Trends und Ideen auf dem Laufenden, 
  die Ihnen das Leben erleichtern können.</p>*/}
    </section>
  )
}

export default AllIdeetipp
