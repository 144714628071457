String.prototype.allReplace = function (obj) {
  var retStr = this
  for (var x in obj) {
    retStr = retStr.replace(new RegExp(x, "g"), obj[x])
  }
  return retStr
}

const deChar = textinput => {
  const uebersetzt = textinput.allReplace({
    Ä: "AE",
    Ö: "OE",
    Ü: "UE",
    ß: "ss",
    ä: "ae",
    ö: "oe",
    ü: "ue",
  })

  return uebersetzt
}
export default deChar
