import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify"
import deChar from "../utils/deChar"

const IdeetippList = ({ ideetipp = [] }) => {
  return (
    <div className="recipes-list">
      {ideetipp.map(ideetip => {
        const { id, title, image, description } = ideetip
        const pathToImage = getImage(image)
        const deDE = deChar(title)
        const slug = slugify(deDE, { lower: true })
        return (
          <Link key={id} to={`/content/${slug}`} className="recipe">
            <GatsbyImage
              image={pathToImage}
              className="recipe-img"
              alt={title}
            />
            <h5>{ideetip.title}</h5>
            {/*Vorbereitung : {ideetip.prepTime} | Dauer : {ideetip.durationTime}*/}
            <p>{ideetip.subtitle}</p>
            <p>
            {/*{ideetip.descion} ...*/}
            </p>
          </Link>
        )
      })}
    </div>
  )
}

export default IdeetippList
